export const DEFAULT_ERROR_TITLE = "Unexpected error";

export const DEFAULT_ERROR_DESCRIPTION =
  "An unexpected error occurred, please try again later";

export const DEFAULT_SERVER_ERROR_TITLE = "Server error";

export const DEFAULT_SERVER_ERROR_DESCRIPTION =
  "An error occurred on the server, please try again later";

export const DEFAULT_DEVICE_UPLOAD_ERROR_TITLE = "Something Went Wrong";

export const DEFAULT_DEVICE_UPLOAD_ERROR_DESCRIPTION =
  "Please upload a different file or try again later.\nIf you need further help, please contact your National Channel Manager or email channel.partners@getakko.com";
