import { ShoppingCartV2Interface } from "../lib/shopping-cart-v2";

export const isShoppingCartValid = (
  shoppingCart: Pick<
    ShoppingCartV2Interface,
    "productPriceId" | "sellingPrice" | "userDefinedId"
  >[],
) => {
  if (shoppingCart.length === 0) {
    return false;
  }

  return shoppingCart.every((item, index) => {
    const hasRequiredFields =
      item.productPriceId && item.sellingPrice && item.sellingPrice > 0;

    if (!hasRequiredFields) {
      return false;
    }

    if (item.userDefinedId) {
      const previousItems = shoppingCart.slice(0, index);
      return !previousItems.some(
        (prevItem) => prevItem.userDefinedId === item.userDefinedId,
      );
    }

    return true;
  });
};
