import { DeviceTypes } from "../types/devices";
import { atom } from "jotai/index";
import { uniqueId } from "lodash";
import { isShoppingCartValid } from "../utils/is-shopping-cart-valid";

export interface ShoppingCartV2Interface {
  quantity: number;
  sellingPrice?: number;
  deviceType: {
    key: string;
    label: DeviceTypes;
  };
  subDeviceType: {
    key: string;
    label: string;
  } | null;
  productPriceId?: string | null;
  msrp?: number;
  value?: number;
  interval?: string;
  coverageTypes?: string[];
  deviceValue?: number;
  serviceFee?: number;
  key: string;
  isSellingPriceDirty: boolean;
  isCaseProtected?: boolean;
  userDefinedId?: string;
  id?: string;
  hasManufacturerWarranty?: boolean;
  sku?: string;
}

export const shoppingCartAtomV2 = atom<ShoppingCartV2Interface[]>([]);

export const areTheyItemsAtShoppingCartAtom = atom<boolean>((get) => {
  const shoppingCart = get(shoppingCartAtomV2);
  return shoppingCart.length > 0;
});

export const addItemToShoppingCartByDeviceTypeAtom = atom(
  null,
  (
    get,
    set,
    args: {
      deviceType: {
        key: string;
        label: DeviceTypes;
      };
      displayOrder: number;
      subDeviceType: {
        key: string;
        label: string;
      } | null;
    },
  ) => {
    const shoppingCartV2 = get(shoppingCartAtomV2);
    const newCart = [...shoppingCartV2];
    newCart.push({
      quantity: 1,
      deviceType: args.deviceType,
      subDeviceType: args.subDeviceType,
      key: uniqueId(),
      isSellingPriceDirty: false,
    });
    set(shoppingCartAtomV2, newCart);
  },
);

export const removeItemFromShoppingCartAtom = atom(
  null,
  (get, set, keyToRemove: string) => {
    const shoppingCartV2 = get(shoppingCartAtomV2);
    const newCart = shoppingCartV2.filter((item) => item.key !== keyToRemove);
    set(shoppingCartAtomV2, newCart);
  },
);

export const isShoppingCartCompleteAtom = atom<boolean>((get) => {
  const shoppingCart = get(shoppingCartAtomV2);
  return isShoppingCartValid(shoppingCart);
});

export const shoppingCartTotalAtom = atom<{
  msrp: number;
  cost: number;
  sellingPrice: number;
  grossProfit: number;
}>((get) => {
  const shoppingCart = get(shoppingCartAtomV2);

  let totalMsrp = 0;
  let totalCost = 0;
  let totalSellingPrice = 0;
  let totalGrossProfit = 0;

  for (const shoppingCartItem of shoppingCart) {
    if (!shoppingCartItem.productPriceId) {
      continue;
    }
    totalMsrp += shoppingCartItem.quantity * Number(shoppingCartItem.msrp || 0);
    totalCost +=
      shoppingCartItem.quantity * Number(shoppingCartItem.value || 0);
    totalSellingPrice +=
      shoppingCartItem.quantity * Number(shoppingCartItem.sellingPrice || 0);
    totalGrossProfit +=
      shoppingCartItem.quantity *
      (Number(shoppingCartItem.sellingPrice) - Number(shoppingCartItem.value));
  }
  return {
    msrp: totalMsrp,
    cost: totalCost,
    sellingPrice: totalSellingPrice,
    grossProfit: totalGrossProfit,
  };
});

export const resetShoppingCartAtom = atom(null, (_get, set) => {
  set(shoppingCartAtomV2, []);
});

export const updateItemsInShoppingCartAtom = atom(
  null,
  (get, set, args: { items: Array<ShoppingCartV2Interface> }) => {
    set(shoppingCartAtomV2, args.items);
  },
);

export const updateShoppingCartPartialItemAtom = atom(
  null,
  (
    get,
    set,
    args: { key: string; payload: Partial<ShoppingCartV2Interface> },
  ) => {
    const shoppingCartV2 = get(shoppingCartAtomV2);

    const updatedCart = shoppingCartV2.map((item) => {
      if (item.key === args.key) {
        return {
          ...item,
          ...args.payload,
        };
      }
      return item;
    });

    set(shoppingCartAtomV2, updatedCart);
  },
);
